import {Dispatch} from "redux";

import {appendQueryString, catch400, catchStalled, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferGroupsSimplified} from "../../offer/detail/types/IOfferGroups";
import {OfferType} from "../../offer/types/OfferType";
import {createDebouncedAction} from "../../store/utils/create_debounced_action";

const FETCH_OFFER_LIST = "offer/FETCH_OFFER_LIST";
export const fetchOfferListTypes = createRequestActionTypes(FETCH_OFFER_LIST);

export interface IOfferQueryOffers {
    address: string;
    geo_point: {
        type: string;
        coordinates: [number, number];
    };
    groups: IOfferGroupsSimplified | null;
    id: number;
    name: string;
    type: OfferType;
    region: IOfferQueryRegions;
    street_name: string | null;
    street_number: string | null;
    vendor: IOfferQueryVendors;
    slug: string;
    stats: {
        properties_count_for_sale: number;
    };
}

export interface IOfferQueryRegions {
    id: number;
    full_name: string;
}

export interface IOfferQueryVendors {
    id: number;
    slug: string;
}

export interface IOfferQueryResponse {
    count: number;
    page_size: number;
    results: IOfferQueryOffers[];
}

const offerListApiLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

const fetchOfferListSimple = (name: string) => (dispatch: Dispatch) => {
    dispatch({type: fetchOfferListTypes.start});

    const url = appendQueryString(offerListApiLink, {
        name,
        page_size: 10,
        for_sale: "True",
        limited_presentation: "False"
    });
    return getRequest({}, url)
        .then((response: IOfferQueryResponse) => {
            dispatch({type: fetchOfferListTypes.success, result: response.results});
            return response; // important for batched fetch
        })
        .catch(
            catch400((err) => {
                dispatch({type: fetchOfferListTypes.error, err});
            })
        )
        .catch(catchStalled((err) => console.warn("Response stalled. Error: ", err)));
};

export const {action: fetchOfferList, clear: stopFetchOfferList} = createDebouncedAction(fetchOfferListSimple, 500);
export const resetOfferList = () => ({type: fetchOfferListTypes.reset});
