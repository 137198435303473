import {parseListedQueryValuesToString} from "@pg-mono/form";

import {searchFormFields} from "../constants/search_form_constants";
import {toQueryValuesWithSearch} from "./to_query_values_with_search";

export const getStringQueryValuesFromFormValues = <TFormValueTypes = typeof searchFormFields>(
    formValues: Record<keyof TFormValueTypes, unknown>
): Record<string, string | string[]> => {
    // handle special case for `search` field
    const queryValues = toQueryValuesWithSearch<TFormValueTypes>(formValues);
    // change values to strings
    return parseListedQueryValuesToString(queryValues);
};
