import React from "react";

import {CenterBox} from "@pg-design/grid";
import {Loader} from "@pg-design/loader";
import {RequestState} from "@pg-mono/request-state";
import {pluralize} from "@pg-mono/string-utils";

import {SearchTab} from "../../../actions/fetch_search_all_action";
import {IRegionListRegion} from "../../../actions/fetch_search_regions_action";
import {IActiveDropdownItemStore} from "../../../reducers/active_dropdown_item_reducer";
import {resultsList} from "../../atoms/atoms";
import {ResultsInfo} from "../../atoms/ResultsInfo";
import {ResultsListItem} from "../../atoms/ResultsListItem";
import {ISearchInputValue} from "../../ISearchInputValue";

export enum RegionsTabLabelTypes {
    NONE,
    OFFERS,
    VENDORS,
    PROMOTIONS
}

interface IOwnProps {
    regions: IRegionListRegion[];
    requestStateObj: {
        fetchRegionsRequest: RequestState;
        fetchPlacesRequest: RequestState;
        fetchOffersRequest: RequestState;
        fetchVendorsRequest: RequestState;
    };
    activeItem: IActiveDropdownItemStore;
    onLinkClick: (option: ISearchInputValue) => void;
    regionsLabelType: RegionsTabLabelTypes;
}
interface IProps extends IOwnProps {}

interface ICountLabelStats {
    promotions_count_total: number;
    properties_all_count_for_sale: number;
    vendors_count_for_sale: number;
}

export const RegionsTab = (props: IProps) => {
    const {regions} = props;
    const {fetchRegionsRequest, fetchPlacesRequest, fetchOffersRequest, fetchVendorsRequest} = props.requestStateObj;
    if (
        fetchRegionsRequest === RequestState.Success &&
        fetchOffersRequest !== RequestState.Waiting &&
        fetchVendorsRequest !== RequestState.Waiting &&
        fetchPlacesRequest !== RequestState.Waiting &&
        regions.length === 0
    ) {
        return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
    }
    if (fetchRegionsRequest === RequestState.Success || fetchRegionsRequest === RequestState.Waiting) {
        if (regions.length === 0) {
            return (
                <CenterBox>
                    <Loader />
                </CenterBox>
            );
        }

        const generateCountLabel = (stats: ICountLabelStats): string | null => {
            switch (props.regionsLabelType) {
                case RegionsTabLabelTypes.OFFERS:
                    return `${stats.properties_all_count_for_sale} ${pluralize(["oferta", "oferty", "ofert"])(stats.properties_all_count_for_sale)}`;
                case RegionsTabLabelTypes.VENDORS:
                    return `${stats.vendors_count_for_sale} ${pluralize(["deweloper", "deweloperzy", "deweloperów"])(stats.vendors_count_for_sale)}`;
                case RegionsTabLabelTypes.PROMOTIONS:
                    return `${stats.promotions_count_total} ${pluralize(["promocja", "promocje", "promocji"])(stats.promotions_count_total)}`;
                default:
                    return null;
            }
        };

        return (
            <ul css={resultsList}>
                {props.regions.map((region, idx) => {
                    const onRegionClick = () => {
                        props.onLinkClick({
                            label: region.full_name_reverted,
                            tabType: SearchTab.Regions,
                            regions: [region]
                        });
                    };

                    return (
                        <ResultsListItem
                            key={idx}
                            title={region.name}
                            subTitle={region.full_name}
                            idx={idx}
                            countLabel={generateCountLabel(region.stats)}
                            onClick={onRegionClick}
                            activeItemId={props.activeItem.id}
                        />
                    );
                })}
            </ul>
        );
    }
    return null;
};
