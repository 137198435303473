import React from "react";
import styled from "@emotion/styled";

import {IFormFieldProps} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {SearchTab} from "../../actions/fetch_search_all_action";
import {IOfferQueryOffers} from "../../actions/fetch_search_offers_action";
import {AutocompletePrediction} from "../../actions/fetch_search_places_action";
import {IRegionListRegion} from "../../actions/fetch_search_regions_action";
import {IVendorListVendor} from "../../actions/fetch_search_vendors_action";
import {setTravelTimeData} from "../../actions/set_travel_time";
import {abroadLinks, holidayLinks} from "../../constants/dropdown_links";
import {IActiveDropdownItemStore} from "../../reducers/active_dropdown_item_reducer";
import {ResultsInfo} from "../atoms/ResultsInfo";
import {ISearchInputValue} from "../ISearchInputValue";
import {RegionTabs} from "../RegionTabs";
import {SearchLinksDropdownList} from "../SearchLinksDropdownList";
import {SuggestedRegions} from "../SuggestedRegions";
import {OffersTab} from "./tab_renderers/OffersTab";
import {PlacesTab} from "./tab_renderers/PlacesTab";
import {RegionsTab, RegionsTabLabelTypes} from "./tab_renderers/RegionsTab";
import {VendorsTab} from "./tab_renderers/VendorsTab";
import {ISearchAutocompleteFormValues} from "./SearchAutocomplete";

export interface IDropdownListOwnProps {
    dropdownIsOpen: boolean;
    search: ISearchInputValue;
    selectedTab: SearchTab;
    regions: IRegionListRegion[];
    requestStateObj: {
        fetchRegionsRequest: RequestState;
        fetchPlacesRequest: RequestState;
        fetchOffersRequest: RequestState;
        fetchVendorsRequest: RequestState;
    };
    activeItem: IActiveDropdownItemStore;
    vendors: IVendorListVendor[];
    places: AutocompletePrediction[];
    offers: IOfferQueryOffers[];
    onLinkClick: (option: ISearchInputValue) => void;
    removeRegionTag: (regionId: number | null) => void;
    onClose?: () => void;
    useTravelTime?: boolean;
    getFieldProps?: <TFieldName extends keyof ISearchAutocompleteFormValues>(
        fieldName: TFieldName
    ) => IFormFieldProps<TFieldName, ISearchAutocompleteFormValues[TFieldName]>;
    setTravelTimeData?: typeof setTravelTimeData;
    prevDistance?: number | string;
    isSearchButtonActive?: boolean;
}

export const SearchAutocompleteDropdown = (props: IDropdownListOwnProps) => {
    const renderActiveTabResults = () => {
        const {selectedTab, useTravelTime} = props;
        if (useTravelTime) {
            return (
                <PlacesTab
                    onLinkClick={props.onLinkClick}
                    fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                    activeItem={props.activeItem}
                    places={props.places}
                    hideListLastSplitLine
                />
            );
        }
        switch (selectedTab) {
            case SearchTab.Regions:
                return (
                    <RegionsTab
                        regionsLabelType={RegionsTabLabelTypes.OFFERS}
                        regions={props.regions}
                        requestStateObj={props.requestStateObj}
                        activeItem={props.activeItem}
                        onLinkClick={props.onLinkClick}
                    />
                );
            case SearchTab.Offers:
                return (
                    <OffersTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        offers={props.offers}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        fetchOffersRequest={props.requestStateObj.fetchOffersRequest}
                    />
                );
            case SearchTab.Vendors:
                return (
                    <VendorsTab
                        onLinkClick={props.onLinkClick}
                        activeItem={props.activeItem}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        fetchVendorsRequest={props.requestStateObj.fetchVendorsRequest}
                        vendors={props.vendors}
                    />
                );
            case SearchTab.Places:
                return (
                    <PlacesTab
                        onLinkClick={props.onLinkClick}
                        fetchPlacesRequest={props.requestStateObj.fetchPlacesRequest}
                        activeItem={props.activeItem}
                        places={props.places}
                    />
                );
            default:
                return null;
        }
    };

    const renderDropdownContent = () => {
        if ([SearchTab.Abroad, SearchTab.Holiday].some((tab) => tab === props.selectedTab)) {
            const links = props.selectedTab === SearchTab.Abroad ? abroadLinks : holidayLinks;
            return <SearchLinksDropdownList links={links} activeItemId={props.activeItem.id} />;
        } else if (props.search.tabType === SearchTab.Regions && props.search.regions.length >= 3) {
            return <ResultsInfo title="Osiągnięto maksymalną liczbę regionów" text="Usuń jakiś region aby dodać nowy lub zacznij nowe szukanie." />;
        } else if (props.search.label && isEmpty(props.search.label) && isEmpty(props.places) && isEmpty(props.offers) && isEmpty(props.vendors)) {
            return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
        } else if (!props.search.label && !props.useTravelTime) {
            return <SuggestedRegions activeItemId={props.activeItem.id} onLinkClick={props.onLinkClick} />;
        } else {
            return <ResultWrapper>{renderActiveTabResults()}</ResultWrapper>;
        }
    };

    return (
        <>
            {!props.useTravelTime && <RegionTabs search={props.search} selectedTab={props.selectedTab} removeRegionTag={props.removeRegionTag} />}
            {renderDropdownContent()}
        </>
    );
};

//results

export const ResultWrapper = styled.div`
    overflow: auto;
    flex-shrink: 1;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        max-height: 29.8rem;
    }
`;
