import {FlagEsIcon} from "@pg-design/icons";
import {rpAppLink} from "@pg-mono/rp-routes";

const url = rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: "za-granica", subcategory: "hiszpania"});

export const abroadLinks = [
    {
        label: "Hiszpania",
        icon: FlagEsIcon,
        url
    }
];

export const holidayLinks = [
    {label: "Nad morzem", suffix: "nad-morzem"},
    {label: "W górach", suffix: "w-gorach"},
    {label: "Nad jeziorem", suffix: "nad-jeziorem"}
].map((category) => {
    const url = rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: "apartamenty-wakacyjne", subcategory: category.suffix});
    return {label: category.label, url};
});
