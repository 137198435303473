import React, {ComponentType, useMemo} from "react";
import {useSelector} from "react-redux";

import {flex, flexDirection, gap, pt} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons";

import {IRPStore} from "../../app/rp_reducer";
import {resultsList} from "./atoms/atoms";
import {ResultsInfo} from "./atoms/ResultsInfo";
import {ResultsListItem} from "./atoms/ResultsListItem";

interface Link {
    label: string;
    url: string;
    icon?: ComponentType<IIcon>;
}

interface IProps {
    activeItemId: number | null;
    links: Link[];
}

export const SearchLinksDropdownList = (props: IProps) => {
    const searchText = useSelector((state: IRPStore) => state.search.formValues.search.label) as string;

    const filteredLinkstList = useMemo(() => {
        return props.links.filter((item) => item.label.toLowerCase().includes(searchText?.toLowerCase() || ""));
    }, [searchText, props.links]);

    if (!filteredLinkstList.length) {
        return (
            <ul css={resultsList}>
                <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />
            </ul>
        );
    }
    return (
        <ul css={resultsList}>
            {filteredLinkstList.map((item, idx) => {
                const Icon = item.icon;
                return (
                    <ResultsListItem
                        key={idx}
                        idx={idx}
                        activeItemId={props.activeItemId}
                        title={
                            <>
                                {!!Icon && <Icon wrapperSize="2.4" wrapperColor="transparent" size="2.4" />}
                                {item.label}
                            </>
                        }
                        href={item.url}
                        onClick={() => null}
                        titleCss={[flex("center"), flexDirection("row"), gap(0.5), pt(1)]}
                        titleAs="div"
                    />
                );
            })}
        </ul>
    );
};
